import { Controller } from "@hotwired/stimulus"

// TODO: Use event listeners for performance
export default class extends Controller {
  static values = {
    json: String
  }

  connect() {
    this.conditions = JSON.parse(this.jsonValue) || {};
    this.checkVisibility();
  }

  checkVisibility(event) {
    // Loop through each condition and update visibility
    Object.entries(this.conditions).forEach(([field, condition]) => {
      let targetElement;
      let querySelector = condition.multiple ? `[name="json[${field}][]"]` : `[name="json[${field}]"]`;
      const targetElements = document.querySelectorAll(querySelector);

      if (targetElements.length > 1) {
        targetElements.forEach((element) => {
          if (element.checked) {
            targetElement = element;
          }
        });
      } else {
        targetElement = targetElements[0];
      }

      // TODO: Multiple target elements
      let dependentElement;
      if (Array.isArray(condition.target)) {
        dependentElement = [];
        condition.target.forEach((target) => {
          const foundElement = this.element.querySelector(`[data-conditional-field-target="${target}"]`);
          if (foundElement) {
            dependentElement.push(foundElement);
          }
        });
      } else {
        dependentElement = this.element.querySelector(`[data-conditional-field-target="${condition.target}"]`);
      }

      if (!targetElement || !dependentElement) return

      // Add or remove event listeners based on field type
      if (targetElement.type === "radio") {
        if (targetElement.value == condition.value) {
          if (Array.isArray(dependentElement)) {
            dependentElement.forEach(element => {
              element.classList.remove('hidden');
            });
          } else {
            dependentElement.classList.remove('hidden');
          }
        } else {
          // remove checked value
          if (Array.isArray(dependentElement)) {
            dependentElement.forEach(element => {
              element.classList.add('hidden');
            });
          } else {
            dependentElement.classList.add('hidden');
          }
          targetElements.forEach(radioButton => {
            radioButton.checked = false;
          });
          targetElement.checked = true;
          this.reset(dependentElement);
        }
      }

      if (targetElement.type === "select-one") {
        if (targetElement.value == condition.value) {
          if (Array.isArray(dependentElement)) {
            dependentElement.forEach(element => {
              element.classList.remove('hidden');
            });
          } else {
            dependentElement.classList.remove('hidden');
          }
        } else {
          if (Array.isArray(dependentElement)) {
            dependentElement.forEach(element => {
              element.classList.add('hidden');
            });
          } else {
            dependentElement.classList.add('hidden');
          }
        }
      }

      if (targetElement.type === "select-multiple") {
        const selectedValues = Array.from(targetElement.selectedOptions).map(option => option.value);

        if (selectedValues.includes(condition.value)) {
          dependentElement.classList.remove('hidden');
        } else {
          dependentElement.classList.add('hidden');
          this.reset(dependentElement);
        }
      }
    });
  }

  reset(element) {
  }
}
