import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "total"]

  connect() {
    this.updateTotal()
    this.inputTargets.forEach(input => {
      input.addEventListener("input", this.updateTotal.bind(this))
    })
  }

  updateTotal() {
    let total = 0
    this.inputTargets.forEach(input => {
      total += parseFloat(input.value) || 0
    })
    this.totalTarget.value = total
  }
}
