// app/javascript/controllers/signature_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["signaturePad", "signatureInput"];

  connect() {
    this.setCanvasDimensions();
    console.log(this.signaturePadTarget.width, this.signaturePadTarget.height);

    this.ctx = this.signaturePadTarget.getContext("2d");
    this.ctx.lineWidth = 2; // Adjust this value to make the stroke thicker
    this.ctx.lineCap = 'round'; // Make the stroke ends rounded
    this.isDrawing = false;

    // Mouse events
    this.signaturePadTarget.addEventListener("mousedown", this.startDrawing.bind(this));
    this.signaturePadTarget.addEventListener("mouseup", this.stopDrawing.bind(this));
    this.signaturePadTarget.addEventListener("mousemove", this.draw.bind(this));

    // Touch events
    this.signaturePadTarget.addEventListener("touchstart", this.startDrawing.bind(this));
    this.signaturePadTarget.addEventListener("touchend", this.stopDrawing.bind(this));
    this.signaturePadTarget.addEventListener("touchmove", this.draw.bind(this));

    this.element.addEventListener("submit", this.saveSignature.bind(this));
  }

  setCanvasDimensions() {
    const rect = this.signaturePadTarget.getBoundingClientRect();
    this.signaturePadTarget.width = rect.width;
    this.signaturePadTarget.height = rect.height;
  }

  getEventOffset(event) {
    if (event.touches && event.touches.length > 0) {
      const touch = event.touches[0];
      const rect = this.signaturePadTarget.getBoundingClientRect();
      return { x: touch.clientX - rect.left, y: touch.clientY - rect.top };
    }
    return { x: event.offsetX, y: event.offsetY };
  }

  startDrawing(event) {
    event.preventDefault();
    this.isDrawing = true;
    const { x, y } = this.getEventOffset(event);
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
  }

  stopDrawing(event) {
    event.preventDefault();
    this.isDrawing = false;
    this.signatureInputTarget.value = this.signaturePadTarget.toDataURL();
  }

  draw(event) {
    event.preventDefault();
    if (!this.isDrawing) return;
    const { x, y } = this.getEventOffset(event);
    this.ctx.lineTo(x, y);
    this.ctx.stroke();
  }

  clear() {
    this.ctx.clearRect(0, 0, this.signaturePadTarget.width, this.signaturePadTarget.height);
    this.signatureInputTarget.value = '';
  }

  saveSignature() {
    if (!this.signatureInputTarget.value) {
      this.signatureInputTarget.value = this.signaturePadTarget.toDataURL();
    }
  }
}
