import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { preview: Boolean }
  static targets = ["page", "cancelSubmit", "prevNext"]

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const pageIndexParam = urlParams.get('page');
    if (pageIndexParam) {
      this.currentPageIndex = parseInt(pageIndexParam);
    }
    else {
      this.currentPageIndex = 0;
    }
    this.showCurrentPage();
  }

  nextPage(event) {
    event.preventDefault();

    if (this.currentPageIndex < this.pageTargets.length - 1) {
      this.currentPageIndex += 1;
      this.showCurrentPage();
    }
  }

  previousPage(event) {
    event.preventDefault();
    if (this.currentPageIndex > 0) {
      this.currentPageIndex -= 1;
      this.showCurrentPage();
    }
  }

  showCurrentPage() {
    this.pageTargets.forEach((page, index) => {
      page.style.display = index === this.currentPageIndex ? 'block' : 'none';
    });

    if (this.pageTargets.length > 1) {
      this.updateButtonVisibility();
    }

    if (!this.previewValue) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('page', this.currentPageIndex);
      window.history.replaceState({}, '', currentUrl);
    }
  }

  updateButtonVisibility() {
    const isLastPage = this.currentPageIndex === this.pageTargets.length - 1;
    this.cancelSubmitTarget.classList.toggle('hidden', !isLastPage);
    this.prevNextTarget.classList.toggle('hidden', isLastPage);
  }
}
