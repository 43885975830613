import { Controller } from "@hotwired/stimulus"
// import ace from "ace-builds";
import JSONEditor from "jsoneditor";


export default class extends Controller {
  static targets = [ "codeEditor", "text" ];

  connect() {
    let options = {
      mode: 'text',
      mainMenuBar: true,
      navigationBar: true,
      statusBar: false,
      showErrorTable: false,
      search: true,
      history: true,
      onChange: () => {
        this.handleCodeChange();
      },
      onValidate: (json) => {
      },
      ace: {
        useWorker: false // Disable Ace validation
      }
    }
    this.editor = new JSONEditor(this.codeEditorTarget, options);

    try {
      const initialText = this.textTarget.value;
      this.editor.setText(initialText);
    } catch (error) {
      console.log(error)
    }
  }

  handleCodeChange() {
    try {
      const code = this.editor.getText();
      this.textTarget.value = code;
    } catch (error) {
    }
  }

  disconnect() {
    if (this.codeEditorTarget) {
      this.editor.destroy();
    }
  }
}
