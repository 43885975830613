// app/javascript/controllers/toggle_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "content"]

  connect() {
    this.toggle()
  }

  toggle() {
    this.contentTarget.classList.toggle("hidden", !this.checkboxTarget.checked)
  }
}
