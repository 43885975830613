import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["element"];

  clear() {
    console.log("Clearing file input!");
    const originalInput = this.element;
    const clonedInput = originalInput.cloneNode(true);
    originalInput.parentNode.replaceChild(clonedInput, originalInput);
  }
}
