import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["target", "template"]

  connect() {
  }

  add(event) {
    event.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
    this.targetTarget.insertAdjacentHTML("beforebegin", content)
  }

  remove(event) {
    event.preventDefault()
    let item = event.target.closest('.multiple-text-field')
    item.remove()
  }
}
