import { Controller } from "@hotwired/stimulus"
import JSONEditor from "jsoneditor";

export default class extends Controller {
  static targets = [ "editor", "text" ]

  connect() {
    const options =  {mode: 'view'}
    this.editor = new JSONEditor(this.editorTarget, options);

    // const initialJson = this.textTarget.value;

    const initialJson = this.textTarget.textContent;
    try {
      const parsedJson = JSON.parse(initialJson);
      this.editor.set(parsedJson);
      this.editor.expandAll();
    } catch (error) {
      // Do nothing
      console.log(error)
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
