import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["emailList"]
  static values = {
    users: Array,
    savedEmails: Array,
    notificationType: String
  }

  connect() {
    if (this.savedEmailsValue.length === 0) {
      this.addEmailField()
    }
  }

  addEmailField(event) {
    if (event) event.preventDefault()
    const emailFieldDiv = document.createElement('div')
    emailFieldDiv.className = 'flex items-center mt-2'
    emailFieldDiv.innerHTML = this.selectTemplate
    this.emailListTarget.appendChild(emailFieldDiv)
  }

  removeEmailField(event) {
    event.preventDefault()
    const emailField = event.target.closest('div')
    const index = event.target.dataset.emailSelectionIndex

    if (index !== undefined && index < this.savedEmailsValue.length) {
      if (confirm('This email was previously saved. Are you sure you want to remove it?')) {
        // Here you would typically send an AJAX request to delete the email from the server
        console.log(`Deleting saved email at index ${index}`)
        emailField.remove()
      }
    } else {
      emailField.remove()
    }

    if (this.emailListTarget.children.length === 0) {
      this.addEmailField()
    }
  }

  get selectTemplate() {
    return `
      <select name="form_configuration[email_options][${this.notificationType}][email_list][]" class="form-control">
        <option value="">Select an email</option>
        ${this.usersValue.map(email => `<option value="${email}">${email}</option>`).join('')}
      </select>
      <button type="button" data-action="click->email-selection#removeEmailField" class="ml-2 text-red-500">Remove</button>
    `
  }

  get notificationType() {
    // You'll need to set this value in your HTML, e.g., as a data attribute
    return this.notificationTypeValue;
  }
}
