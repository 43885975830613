import { Controller } from "@hotwired/stimulus"
import JSONEditor from "jsoneditor";
import Ajv from "ajv";

export default class extends Controller {
  static values = { schema: {type: String, default: "{}" } };
  static targets = [ "editor", "submit", "text" ];

  connect() {
    const options =  {
      ajv: Ajv({
        allErrors: true,
        verbose: true,
        jsonPointers: false,
        $data: true,
      }),
      schema: JSON.parse(this.schemaValue),
      mode: 'code',
      mainMenuBar: true,
      navigationBar: true,
      statusBar: true,
      search: true,
      history: true,
      enableSort: false,
      enableTransform: false,
      onChange: () => {
        this.handleJSONChange();
      }
    }
    this.editor = new JSONEditor(this.editorTarget, options);

    // Try to initialize the editor with the current value of the textarea
    const initialJson = this.textTarget.value;
    try {
      const parsedJson = JSON.parse(initialJson);
      this.editor.set(parsedJson);
    } catch (error) {
      //pass
    }
  }

  handleJSONChange() {
    try {
      const json = this.editor.get();
      this.textTarget.value = JSON.stringify(json, null, 2);
    } catch (error) {
      //pass
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
