import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]

  connect() {
  }

  submit(event) {
    event.stopPropagation()
    this.enableAllFormElements()
  }

  enableAllFormElements() {
    this.formTarget.querySelectorAll('input, select, textarea, button').forEach(element => {
      element.disabled = false
    })
  }
}
