// app/javascript/controllers/copy_field_json_controller.js

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-json"
export default class extends Controller {
  static targets = ["dt"]

  connect() {
    console.log("Connected to copy-field-json controller")
    this.dtTargets.forEach((dt) => {
      dt.addEventListener("click", this.copyToClipboard.bind(this))
    })
  }

  copyToClipboard(event) {
    const dtElement = event.currentTarget
    const inputType = dtElement.querySelector("code").textContent.trim()

    const jsonObject = {
      id: "REPLACE_THIS_FIELD_ID", // replace with your field ID logic if needed
      label: "REPLACE THIS FIELD LABEL", // replace with your field label logic if needed
      input_type: inputType
    }

    if (['file', 'multiple_text', 'select'].includes(inputType)) {
      jsonObject.multiple = true
    }

    const jsonString = JSON.stringify(jsonObject, null, 2)

    navigator.clipboard.writeText(jsonString).then(() => {
      this.showMessage(dtElement, "Field copied!")
    }).catch(err => {
    })
  }


  showMessage(dtElement, text) {
    const messageElement = document.createElement('span')
    messageElement.textContent = text
    messageElement.className = 'copy-message'
    dtElement.appendChild(messageElement)

    setTimeout(() => {
      messageElement.remove()
    }, 3000)
  }
}
