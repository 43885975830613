import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "total"]

  connect() {
    this.updateTotal()
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener("change", this.updateTotal.bind(this))
    })
  }

  updateTotal() {
    let total = 0
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox.checked) {
        total += 1
      }
    })
    this.totalTarget.value = total
  }
}
