// app/javascript/controllers/reports_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    account: String
  }
  static targets = ["checkbox", "content", "formKeysContainer", "filtersContainer"]

  connect() {
    console.log("connected ReportsController")
  }

  selectForm(event) {
    const formId = event.target.value
    console.log("Selected form ID:", formId)

    if (formId) {
      this.loadFormKeys(formId)
    } else {
      this.clearFormKeys()
    }
  }

  loadFormKeys(formId) {
    const url = `/accounts/${this.accountValue}/reports/${formId}/form_keys`
    fetch(url, {
      headers: {
        "Accept": "text/vnd.turbo-stream.html"
      }
    })
    .then(response => response.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
      this.addInitialFormKeySelect()
    })
    .catch(error => console.error("Error loading form keys:", error))
  }

  clearFormKeys() {
    const streamMessage = `
      <turbo-stream action="update" target="form-keys-container">
        <template></template>
      </turbo-stream>
    `
    Turbo.renderStreamMessage(streamMessage)
  }

  addFormKeySelect() {
    const container = this.formKeysContainerTarget
    const firstSelect = container.querySelector("select")
    if (firstSelect) {
      const newSelect = firstSelect.cloneNode(true)
      newSelect.value = "" // Reset the selected value
      container.appendChild(newSelect)
    }
  }

  addInitialFormKeySelect() {
    const container = this.formKeysContainerTarget
    if (!container.querySelector("select")) {
      this.addFormKeySelect()
    }
  }

  addFilterRow() {
    const filterRow = this.createFilterRow()
    this.filtersContainerTarget.appendChild(filterRow)
  }

  createFilterRow() {
    const row = document.createElement('div')
    row.className = 'flex mb-2'

    const select = document.createElement('select')
    select.name = 'filters[][column]'
    select.className = 'form-control mr-2'
    this.populateFilterColumnOptions(select)

    const input = document.createElement('input')
    input.type = 'text'
    input.name = 'filters[][value]'
    input.className = 'form-control mr-2'
    input.placeholder = 'Enter filter value'

    const removeButton = document.createElement('button')
    removeButton.type = 'button'
    removeButton.className = 'btn btn-danger'
    removeButton.textContent = 'Remove'
    removeButton.addEventListener('click', () => row.remove())

    row.appendChild(select)
    row.appendChild(input)
    row.appendChild(removeButton)

    return row
  }

  populateFilterColumnOptions(select) {
    const formKeys = this.getFormKeys()
    select.innerHTML = '<option value="">Select a column</option>'
    formKeys.forEach(key => {
      const option = document.createElement("option")
      option.value = key
      option.textContent = key
      select.appendChild(option)
    })
  }

  getFormKeys() {
    return Array.from(this.formKeysContainerTarget.querySelectorAll("select"))
      .flatMap(select => Array.from(select.options).map(option => option.value))
      .filter(value => value !== "")
  }

  updateFilterColumnOptions() {
    const formKeys = this.getFormKeys()
    this.filtersContainerTarget.querySelectorAll('select').forEach(select => {
      const currentValue = select.value
      select.innerHTML = '<option value="">Select a column</option>'
      formKeys.forEach(key => {
        const option = document.createElement("option")
        option.value = key
        option.textContent = key
        select.appendChild(option)
      })
      select.value = currentValue
    })
  }
}
